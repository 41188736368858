
import { defineComponent, onMounted, ref } from "vue";
import apiService from "@/core/services/ApiService";
import GlobalTable from "@/components/widgets/tables/GlobalTable.vue";
import SectionModal from "@/components/modals/forms/SectionModal.vue";
import { Modal } from "bootstrap";
import DeleteSection from "@/components/swal/DeleteSection.vue";
import { useRouter } from "vue-router";
import DropdownExport from "@/components/dropdown/DropdownExport.vue";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

export default defineComponent({
  name: "edit_section_table",
  components: { GlobalTable, SectionModal, DeleteSection, DropdownExport },
  setup() {
    let item = ref();
    let change = ref<boolean>(false);
    let getDataExist = ref<boolean>(true);
    let updaterID = ref(null);
    let deleterData = ref({});
    let checkDeleter = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const currentSort = ref<string>("");
    const column = ref<string>("id");
    const orderType = ref<string>("desc");
    let search = ref<string | null>(null);
    let pagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 1,
    });
    let page = ref<number>(1);
    let perPage = ref<number>(10);
    let data = ref([]);
    const router = useRouter();

    const tableHeader = [
      {
        name: "ID",
        key: "id",
        sortable: false,
      },
      {
        name: "Code",
        key: "code",
        sortable: false,
      },
      {
        name: "Description",
        key: "description",
        sortable: false,
      },
      {
        name: "Order",
        key: "order",
        sortable: false,
      },
      {
        name: "Status",
        key: "status",
        sortable: false,
      },
      {
        name: "Comment",
        key: "comment",
        sortable: false,
      },
      {
        name: "Actions",
        key: "actions",
        sortable: false,
      },
    ];
    onMounted(() => {
      getSections(perPage.value, page.value, column.value, orderType.value);
    });

    function getSections(perPage, page, column, orderType) {
      search.value = null;
      loading.value = true;
      apiService
        .get(
          "/section",
          `?perPage=${perPage}&page=${page}&column=${column}&orderType=${orderType}`
        )
        .then(function (resp) {
          if (resp.data.data) {
            data.value = resp.data?.data;
            //pagination.value.page = resp.data?.meta.current_page;
            //pagination.value.total = resp.data?.meta.total;
            //pagination.value.rowsPerPage = resp.data?.meta.per_page;
            loading.value = false;
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    function searchSections(perPage, page, query, column, orderType) {
      loading.value = true;
      apiService
        .get(
          "/section/search",
          `?query=${query}&perPage=${perPage}&page=${page}&column=${column}&orderType=${orderType}`
        )
        .then(function (resp) {
          if (resp.data.data) {
            data.value = resp.data.data;
            //pagination.value.page = resp.data.meta.current_page;
            //pagination.value.total = resp.data.meta.total;
            //pagination.value.rowsPerPage = resp.data.meta.per_page;
            loading.value = false;
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    const setItemsPerPage = (event) => {
      page.value = 1;
      perPage.value = event.target.value;
      if (search.value != null && search.value != "") {
        searchSections(
          perPage.value,
          page.value,
          search.value,
          column.value,
          orderType.value
        );
      } else {
        getSections(perPage.value, page.value, column.value, orderType.value);
      }
    };

    const currentPageChange = (val) => {
      page.value = val;
      //pagination.value.page = val;
      if (search.value != null && search.value != "") {
        searchSections(
          perPage.value,
          val,
          search.value,
          column.value,
          orderType.value
        );
      } else {
        getSections(perPage.value, val, column.value, orderType.value);
      }
    };

    const setSearchValue = (event) => {
      search.value = event.target.value;
      if (!search.value?.trim()) {
        getSections(perPage.value, page.value, column.value, orderType.value);
        return;
      }
      searchSections(
        perPage.value,
        page.value,
        search.value,
        column.value,
        orderType.value
      );
    };
    const newOne = (resp) => {
      if (resp === "new") {
        newSection();
      }
    };
    const orderedData = (resp) => {
      column.value = resp.column;
      orderType.value = resp.sorting;
      getSections(perPage.value, page.value, resp.column, resp.sorting);
    };
    const existData = async (resp) => {
      getDataExist.value = false;
      if (resp) {
        getDataExist.value = await resp;
      }
    };
    const checkModal = (resp) => {
      if (resp.status == "updated") {
        let array = JSON.parse(JSON.stringify(data.value));
        const foundIndex = array.findIndex((x) => x.id == resp.data.id);
        array[foundIndex] = resp.data;
        data.value = new Proxy(array, {});
      } else if (resp.status == "created") {
        page.value = 1;
        search.value = null;
        //pagination.value.page = resp.data?.meta.current_page;
        //pagination.value.total = resp.data?.meta.total;
        //pagination.value.rowsPerPage = resp.data?.meta.per_page;
        data.value = new Proxy(resp.data?.data, {});
      } else if (resp.status == "deleted") {
        search.value = null;
        //pagination.value.page = resp.data?.meta.current_page;
        //pagination.value.total = resp.data?.meta.total;
        //pagination.value.rowsPerPage = resp.data?.meta.per_page;
        data.value = new Proxy(resp.data?.data, {});
      }
    };

    const editSection = (data) => {
      data.status = Boolean(data.status);
      item.value = data;
      change.value = !change.value;
      updaterID.value = data.id;
      const modal = new Modal(document.getElementById("kt_modal_section"));
      modal.show();
    };

    const newSection = () => {
      item.value = null;
      change.value = !change.value;
      updaterID.value = null;
      const modal = new Modal(document.getElementById("kt_modal_section"));
      modal.show();
    };

    const deleteSection = (id, code) => {
      checkDeleter.value = !checkDeleter.value;
      deleterData.value = {
        id: id,
        code: code,
      };
    };

    return {
      column,
      orderType,
      orderedData,
      getDataExist,
      existData,
      loading,
      tableHeader,
      data,
      setItemsPerPage,
      currentPageChange,
      pagination,
      perPage,
      page,
      search,
      setSearchValue,
      currentSort,
      checkModal,
      newOne,
      editSection,
      item,
      newSection,
      updaterID,
      checkDeleter,
      deleterData,
      change,
      deleteSection,
    };
  },
});
