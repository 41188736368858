
import { defineComponent, onMounted } from "vue";
import SectionTable from "@/components/widgets/tables/SectionTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "sections",
  components: {
    SectionTable,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Sections");
    });
  },
});
