
import { defineComponent, ref, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Modal } from "bootstrap";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import apiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "section-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    data: {
      type: Object,
    },
    change: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
    },
    page: {
      required: true,
    },
    perPage: {
      required: true,
    },
    column: {
      required: true,
    },
    orderType: {
      required: true,
    },
  },
  emit: ["modal-value", "new-value"],

  setup: function (props, { emit }) {
    const router = useRouter();
    let submitButtonRef = ref<null | HTMLButtonElement>(null);
    const sectionModalRef = ref<null | HTMLElement>(null);
    let formRef = ref<null | HTMLElement>();
    let errorsRef = ref(null);
    let canSend = ref<boolean>(true);

    let sectionData = ref({
      code: "",
      description: "",
      status: false,
      order: null,
      comment: null,
      perPage: props.perPage,
      page: props.page,
      column: props.column,
      orderType: props.orderType,
    });

    watch(
      () => props.change,
      () => {
        canSend.value = true;
        if (props.id) {
          Object.assign(sectionData.value, props.data);
        } else {
          sectionData.value = {
            code: "",
            description: "",
            status: false,
            order: null,
            comment: null,
            perPage: props.perPage,
            page: props.page,
            column: props.column,
            orderType: props.orderType,
          };
          let button = document.getElementById("kt_modal_section_cancel");
          button?.click();
        }
        errorsRef.value = null;
      }
    );
    const validationSchema = Yup.object().shape({
      code: Yup.string().min(2).max(10).required().label("Section Code"),
      description: Yup.string().required().label("Section Description"),
      order: Yup.number().required().label("Section Order"),
    });

    const hideError = (key) => {
      if (key && errorsRef.value) {
        let array = JSON.parse(JSON.stringify(errorsRef.value));
        delete array[key];
        errorsRef.value = new Proxy(array, {});
      }
    };

    function getError(error) {
      errorsRef.value = error;
      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
    }

    function success(data, method) {
      emit("modal-value", data);
      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
      if(method === "created"){
        Swal.fire({
          text: `The section has been successfully ${data.status}!`,
          icon: "success",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Add Section",
          cancelButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        }).then((result) => {
          hideModal(sectionModalRef.value);
          if (result.isConfirmed) {
            canSend.value = true;
            emit("new-modal", "new");
          }
        });
      } else if (method === "updated") {
        Swal.fire({
          text: `The question has been successfully ${data.status}!`,
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          canSend.value = true;
          hideModal(sectionModalRef.value);
        });
      }
    }

    const submit = async () => {
      if(!canSend.value){
        return false;
      }
      let array = JSON.parse(JSON.stringify(errorsRef.value));
      let count = array ? Object.keys(array).length : 0;
      if (!submitButtonRef.value || count > 0) {
        return;
      }
      submitButtonRef.value.disabled = true;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      if (props.id) {
        await apiService
          .put("/section/" + props.id, sectionData.value)
          .then((resp) => {
            let data = {
              status: "updated",
              data: resp.data.data,
            };
            success(data, "updated");
            canSend.value = false;
          })
          .catch(function (error) {
            canSend.value = true;
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
            if (error.response && error.response.status == 401) {
              router.push({ name: "sign-in" });
            }
            if (error.response && error.response.status == 422) {
              getError(error.response?.data.errors);
            }
          });
      } else {
        await apiService
          .post("/section", sectionData.value)
          .then((resp) => {
            let data = {
              status: "created",
              data: resp.data,
            };
            canSend.value = false;
            success(data, "created");
          })
          .catch(function (error) {
            canSend.value = true;
            if (error.response && error.response.status == 401) {
              router.push({ name: "sign-in" });
            }
            if (error.response && error.response.status == 422) {
              getError(error.response?.data.errors);
            }
          });
      }
      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
    };

    return {
      canSend,
      validationSchema,
      submit,
      submitButtonRef,
      formRef,
      sectionData,
      sectionModalRef,
      errorsRef,
      hideError,
    };
  },
});
